import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import {
  type SanityProductFragment,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { AnalyticsEventName } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { useAddItemsToCart } from '@lib/cart/hooks'
import { getDefaultOption } from '@lib/product/option'
import { getVariantByDefaultOption } from '@lib/product/variant'
import { StringsContext } from '@lib/strings-context'
import { UserContext } from '@lib/user/context'
import { getHasUserPurchasePermission } from '@lib/user/helpers'

import ProductAddToCart from '@blocks/product/product-add-to-cart'
import ProductCounter from '@blocks/product/product-counter'
import ProductOptionsSelect from '@blocks/product/product-options-select'
import ProductStockLabel from '@blocks/product/product-stock'
import Alert from '../alert'
import { ButtonVariant } from '../buttons/button'
import SimplePortableText from '../simple-portable-text'

interface QuickAddModalProductControlsProps {
  product: SanityProductFragment
}

const QuickAddModalProductControls = ({
  product,
}: QuickAddModalProductControlsProps) => {
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)
  const strings = useContext(StringsContext)
  const { user } = useContext(UserContext)

  const addItemsToCart = useAddItemsToCart()

  const [quantity, setQuantity] = useState(1)
  const [isAddToCartError, setIsAddToCartError] = useState(false)
  const [activeVariantId, setActiveVariantId] = useState<number | null>(null)

  // Get active product and variant
  const activeVariant = useMemo(
    () =>
      product?.variants?.find(
        (variant) => variant.variantID === activeVariantId
      ),
    [activeVariantId, product]
  )

  // Unset values when product is missing
  useEffect(() => {
    if (product) {
      return
    }

    setQuantity(1)
    setIsAddToCartError(false)
    setActiveVariantId(null)
  }, [product])

  // Set default active variant ID when opening modal
  useEffect(() => {
    if (product && activeVariantId === null) {
      const firstVariantId = product.variants?.[0]?.variantID

      if (firstVariantId) {
        setActiveVariantId(firstVariantId)
      }
    }
  }, [activeVariantId, product])

  const handleVariantChange = useCallback(
    (newVariant: SanityProductVariantFragment) => {
      if (!product) {
        return
      }

      const variantIds =
        product.variants?.map((variant) => variant.variantID) ?? []
      const isValidVariantId = variantIds.some(
        (id) => id === newVariant.variantID
      )

      if (isValidVariantId) {
        setActiveVariantId(newVariant.variantID)
        return
      }

      // Set default variant
      const defaultOption = getDefaultOption(
        product.options,
        product.optionSettings ?? []
      )
      const defaultVariant = getVariantByDefaultOption(
        product.variants ?? [],
        defaultOption
      )
      const defaultVariantId = defaultVariant?.variantID

      if (defaultVariantId) {
        setActiveVariantId(defaultVariantId)
        return
      }

      // Set first variant
      const firstVariantId = product.variants?.[0]?.variantID

      if (firstVariantId) {
        setActiveVariantId(firstVariantId)
      }
    },
    [product]
  )

  const handleAddToCart = useCallback(async () => {
    setIsAddToCartError(false)

    if (!activeVariant) {
      return
    }

    const isSuccessful = await addItemsToCart([
      {
        id: activeVariant.variantID,
        quantity,
        productId: product._id,
        productCategoryId: product.productCategoryId,
        collectionIds: product.collectionIds,
      },
    ])

    setIsAddToCartError(!isSuccessful)

    if (isSuccessful) {
      triggerAnalyticsEvent(AnalyticsEventName.AddToCart)
    }
  }, [activeVariant, addItemsToCart, product, quantity, triggerAnalyticsEvent])

  const hasUserPurchasePermission = useMemo(
    () => getHasUserPurchasePermission(product.productType, user),
    [product, user]
  )

  if (!product || !activeVariant) {
    return null
  }

  const isUserLoading = typeof user === 'undefined'
  const hasAddToCart = !isUserLoading && hasUserPurchasePermission
  const isInStock = activeVariant.inStock

  return (
    <>
      <div className="overflow-y-scroll no-scrollbar px-5 pb-5">
        <ProductOptionsSelect
          product={product}
          activeVariant={activeVariant}
          onChange={handleVariantChange}
        />
      </div>

      {hasAddToCart && (
        <div className="p-5">
          {isInStock && (
            <>
              <div className="flex gap-6">
                <ProductCounter
                  max={10}
                  onUpdate={setQuantity}
                  className="flex-none"
                />

                <ProductAddToCart
                  variant={ButtonVariant.FILLED}
                  onClick={handleAddToCart}
                  className="flex-auto"
                >
                  {strings.buttonAddToCart}
                </ProductAddToCart>
              </div>

              {isAddToCartError && (
                <Alert error className="mt-4" key="error">
                  <SimplePortableText
                    className="rc rc-alert rc-error"
                    content={strings.cartAddToCartErrorMessage}
                  />
                </Alert>
              )}
            </>
          )}

          {!isInStock && (
            <div className="text-center">
              <ProductStockLabel productVariant={activeVariant} size="sm" />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default QuickAddModalProductControls
