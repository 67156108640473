import cx from 'classnames'
import { motion } from 'framer-motion'
import {
  forwardRef,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react'

import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { itemAnimation } from '@lib/animate'
import { CartContext } from '@lib/cart/context'
import {
  getCollectionDiscounts,
  getProductDiscounts,
} from '@lib/discount/product'
import { getFilteredProductVariant } from '@lib/product/card'
import {
  getDocumentPercentDiscount,
  getProductPrices,
} from '@lib/product/price'
import { ProductQuickAddContext } from '@lib/product/quick-add-context'
import { type Filter } from '@lib/product/types'
import { getLinkPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'
import { UserContext } from '@lib/user/context'
import {
  getHasUserPurchasePermission,
  getHasUserViewPricePermission,
} from '@lib/user/helpers'

import ProductPrice from '@blocks/product/product-price'
import ProductThumbnail from '@blocks/product/product-thumbnail'
import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  getButtonStyles,
} from '@components/buttons/button'
import SimpleLink from '@components/simple-link'
import Icon from '@components/icon'

interface ProductCardProps {
  product: SanityProductFragment
  showPrice?: boolean
  showQuickAdd?: boolean
  activeFilters?: Filter[]
  className?: string
  onClick?: () => void
}

const ProductCard = forwardRef<HTMLDivElement, ProductCardProps>(
  (
    { product, showPrice, showQuickAdd, activeFilters, className, onClick },
    ref
  ) => {
    const { cart, cartAddingProductIds } = useContext(CartContext)
    const { openProductQuickAdd } = useContext(ProductQuickAddContext)
    const strings = useContext(StringsContext)
    const { user } = useContext(UserContext)

    const id = useId()

    const [activeVariant, setActiveVariant] = useState(() =>
      getFilteredProductVariant(product, activeFilters ?? [])
    )

    useEffect(
      () =>
        setActiveVariant(
          getFilteredProductVariant(product, activeFilters ?? [])
        ),
      [activeFilters, product]
    )

    const [price, comparePrice] = useMemo(() => {
      if (product.productType === 'd2cPriceOnRequest') {
        return [,] as const
      }

      const productDiscounts = getProductDiscounts(
        user?.company?.productDiscounts
      )
      const collectionDiscounts = getCollectionDiscounts(
        user?.company?.collectionDiscounts
      )
      const productPercentDiscount = getDocumentPercentDiscount(
        productDiscounts ?? [],
        'product',
        product._id ? [product._id] : []
      )
      const collectionPercentDiscount = getDocumentPercentDiscount(
        collectionDiscounts ?? [],
        'collection',
        product.collectionIds ?? []
      )

      return getProductPrices({
        productPrice: product.price,
        productComparePrice: product.comparePrice,
        variantPrice: activeVariant?.price,
        variantComparePrice: activeVariant?.comparePrice,
        productPercentDiscount,
        collectionPercentDiscount,
        companyPercentDiscount: user?.company?.percentDiscount,
      })
    }, [activeVariant, product, user])

    const hasUserPurchasePermission = useMemo(
      () => getHasUserPurchasePermission(product.productType, user),
      [product, user]
    )

    const hasUserViewPricePermission = useMemo(
      () => getHasUserViewPricePermission(product.productType, user),
      [product, user]
    )

    const isPriceOnRequest = product.productType === 'd2cPriceOnRequest'
    const isUserLoading = typeof user === 'undefined'

    const galleryPhotos = product.galleryPhotos ?? []
    const listingPhotos = product.listingPhotos ?? []
    const hasThumbnails = listingPhotos.length > 0
    const hasQuickAdd =
      showQuickAdd && !isPriceOnRequest && hasUserPurchasePermission && !!cart
    const hasPrice =
      showPrice &&
      typeof price !== 'undefined' &&
      !isPriceOnRequest &&
      !isUserLoading &&
      hasUserViewPricePermission
    const isAddingToCart = activeVariant
      ? cartAddingProductIds.includes(activeVariant.variantID)
      : false

    // Product URL
    const productUrl = getLinkPageUrl({
      pageType: 'product',
      slugs: product.slug.current,
    })

    return (
      <motion.div
        ref={ref}
        variants={itemAnimation}
        className={cx('flex flex-col gap-4 group', className)}
      >
        {!!activeVariant && hasThumbnails && (
          <div className="relative border">
            <SimpleLink
              href={productUrl}
              onClick={onClick}
              onBeforeInput={onClick}
              tabIndex={0}
            >
              <ProductThumbnail
                galleryPhotos={galleryPhotos}
                listingPhotos={listingPhotos}
                activeVariant={activeVariant}
              />
            </SimpleLink>

            {hasQuickAdd && (
              <div className="absolute bottom-2 left-2 group/quick-add">
                <button
                  disabled={!cart || isAddingToCart}
                  onClick={(event) => {
                    // Prevent immediate closing of quick add modal by stopping event propagation
                    event.stopPropagation()

                    openProductQuickAdd(product)
                  }}
                  className={cx(
                    'group',
                    getButtonStyles({
                      variant: ButtonVariant.OUTLINED,
                      size: ButtonSize.XS,
                      color: ButtonColor.GRAY,
                      icon: 'Plus',
                    }),
                    'relative z-[5] bg-white'
                  )}
                >
                  <span className="text-[20px]">
                    <Icon
                      id={`product-quick-add-plus-icon-${id}`}
                      name="Plus"
                    />
                  </span>
                </button>

                <span
                  className={cx(
                    'absolute -left-[3px] -top-[3px] pr-[18px] pl-[42px] z-[4]',
                    'rounded-full leading-[36px] font-medium text-xs sm:text-[14px] whitespace-nowrap bg-[rgba(34,34,34,.7)] text-white',
                    'opacity-0 group-hover/quick-add:opacity-100 transition-all'
                  )}
                >
                  {strings.buttonSeeOptions}
                </span>
              </div>
            )}
          </div>
        )}

        <div className="space-y-2">
          <div className="">
            <SimpleLink
              className="block is-h4 mb-1"
              href={productUrl}
              onClick={onClick}
              onBeforeInput={onClick}
              tabIndex={0}
            >
              {product.title}
            </SimpleLink>

            {hasPrice && (
              <ProductPrice
                price={price}
                comparePrice={comparePrice}
                size="sm"
                showPrice
                showOldPrice
                showCurrency
                className="pt-[2px] gap-y-[1px]"
              />
            )}
          </div>
        </div>
      </motion.div>
    )
  }
)

ProductCard.displayName = 'ProductCard'

export default ProductCard
