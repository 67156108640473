import { ReactNode } from 'react'
import NextLink from 'next/link'
import cx from 'classnames'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { type SanityFooterSettings } from '@data/sanity/queries/types/site'

import Menu from '@blocks/navigation/menu'
import Photo from '@components/photo'
import Icon, { IconName } from '@components/icon'

interface FooterBlockProps {
  title?: string
  children?: ReactNode
}

const FooterBlock = ({ title, children }: FooterBlockProps) => (
  <div className="flex flex-col gap-y-5">
    <h4>{title}</h4>
    {children}
  </div>
)

interface FooterLinkProps {
  id: string
  children: ReactNode
  href?: string
  icon: IconName
}

const FooterLink = ({ id, children, href, icon }: FooterLinkProps) => (
  <a
    href={href}
    className={cx('inline-flex items-start gap-x-2.5 text-base group', {
      'pointer-events-none': !href,
    })}
  >
    <span className="bg-white bg-opacity-[0.15] rounded-full p-2 text-xl">
      <Icon id={id} name={icon} />
    </span>
    <span className="mt-2 group-hover:opacity-60 transition-opacity duration-200">
      {children}
    </span>
  </a>
)

type FooterProps = SanityFooterSettings & {
  logo?: SanityImageFragment
}

const Footer = ({
  company,
  menus,
  legalMenu,
  copyright,
  logo,
}: FooterProps) => {
  return (
    <footer
      className="relative overflow-hidden bg-blue text-pageBG pt-16"
      role="contentinfo"
    >
      <div className="container flex flex-col gap-y-12">
        {!!logo && (
          <NextLink href="/" className="inline-flex">
            <Photo image={logo} />
          </NextLink>
        )}

        <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-10">
          {!!company && (
            <FooterBlock title={company.name}>
              <div className="flex flex-col gap-y-3">
                {!!company.phone && (
                  <FooterLink
                    href={`tel:${company.phone}`}
                    id="footer-company-phone"
                    icon="Dispatcher"
                  >
                    {company.phone}
                  </FooterLink>
                )}
                {!!company.email && (
                  <FooterLink
                    href={`mailto:${company.email}`}
                    id="footer-company-email"
                    icon="Mail"
                  >
                    {company.email}
                  </FooterLink>
                )}
                {!!company.address && (
                  <FooterLink id="footer-company-address" icon="Globe">
                    {company.address}
                  </FooterLink>
                )}
              </div>
            </FooterBlock>
          )}

          {!!menus && (
            <>
              {menus.map(({ title, items }, index) => (
                <FooterBlock title={title} key={`footer-block-${index}`}>
                  <Menu items={items} isFooterMenu />
                </FooterBlock>
              ))}
            </>
          )}
        </div>

        <div className="flex flex-col xs:flex-row xs:items-center xs:flex-wrap gap-5 py-5 xs:mt-10 border-t border-white border-opacity-10">
          {!!copyright && <p className="text-xxs">{copyright}</p>}
          {!!legalMenu && <Menu items={legalMenu.items} isFooterLegalMenu />}

          <a
            className="inline-flex gap-2.5 justify-center align-bottom text-xxs mx-auto xs:ml-auto xs:mr-0 group"
            href="https://nethart.com"
          >
            <span className="opacity-30">Built by</span>
            <svg
              viewBox="0 0 76 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="inline-block h-[13px] fill-white opacity-30 group-hover:opacity-100 transition-opacity"
            >
              <path
                d="M6.90528 8.02229L2.4837 0.0263672H0.0478516V12.9734H2.4837V5.03044L6.87881 12.9734H6.90528H9.31465V0.0263672H6.90528V8.02229Z"
                className="fill-current"
              />
              <path
                d="M11.618 0.0263672V12.9734H19.1109V10.564H14.0539V7.70457H17.9195V5.29521H14.0539V2.43574H19.1109V0.0263672H11.618Z"
                className="fill-current"
              />
              <path
                d="M21.1497 0.0263672V2.43574H24.4328V12.9734H26.8421V2.43574H30.1252V0.0263672H21.1497Z"
                className="fill-current"
              />
              <path
                d="M66.1334 0.0263672V2.43574H69.4165V12.9734H71.8259V2.43574H75.109V0.0263672H66.1334Z"
                className="fill-current"
              />
              <path
                d="M38.9949 5.13635H34.5733V0.0263672H32.1375V12.9734H34.5733V7.57219H38.9949V12.9734H41.4043V0.0263672H38.9949V5.13635Z"
                className="fill-current"
              />
              <path
                d="M49.7444 0.0263672H47.3615H47.2027L43.443 12.9999H45.9583L46.779 10.1669H50.274L51.0947 12.9999H53.61L49.8768 0.0263672H49.7444ZM49.5856 7.73105H47.4939L48.553 4.13024L49.5856 7.73105Z"
                className="fill-current"
              />
              <path
                d="M62.6384 7.89002C63.0885 7.65173 63.4857 7.33401 63.8034 6.93686C64.3064 6.30143 64.6241 5.50713 64.6241 4.60693V3.68024C64.6241 3.25662 64.5447 2.83299 64.4123 2.43585C64.0946 1.53564 63.4062 0.794297 62.559 0.370672C62.0824 0.132383 61.5264 0 60.9439 0H55.7015V12.9735H58.1109H58.1374V8.31365H60.1761L62.1353 13H64.783L62.6384 7.89002ZM62.1618 4.6334C62.1618 5.03055 61.9765 5.40122 61.6588 5.61303C61.447 5.77189 61.1822 5.8778 60.9174 5.8778H58.1109V2.46232H60.9174C61.341 2.46232 61.7117 2.67413 61.9235 2.99185C62.0559 3.20367 62.1353 3.44195 62.1353 3.70672V4.6334H62.1618Z"
                className="fill-current"
              />
            </svg>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
