import { ReactNode, useContext, useRef } from 'react'

import { useIsTouchDevice } from '@lib/helpers'
import { SearchContext } from '@lib/search-context'
import { SiteContext } from '@lib/site-context'

import DropdownButton from './dropdown-button'

interface MegaNavigationButtonProps {
  id: string
  children?: ReactNode
  className?: string
}

const MegaNavigationButton = ({
  id,
  children,
  className,
}: MegaNavigationButtonProps) => {
  const isTouchDevice = useIsTouchDevice()
  const {
    megaNavigation,
    megaNavigationTimeout,
    setMegaNavigationTimeout,
    toggleMegaNavigation,
  } = useContext(SiteContext)
  const { toggleSearchDrawer } = useContext(SearchContext)

  const isActive = megaNavigation?.activeId === id

  const btnRef = useRef<HTMLDivElement | null>(null)

  const handleClick = () => {
    if (isTouchDevice) {
      toggleSearchDrawer(false)
      toggleMegaNavigation(!isActive ? true : 'toggle', id, getLeftOffset())
    }
  }

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      if (megaNavigationTimeout) {
        window.clearTimeout(megaNavigationTimeout)
      }

      toggleSearchDrawer(false)
      toggleMegaNavigation(true, id, getLeftOffset())
    }
  }

  const getLeftOffset = (): number => {
    const rect = btnRef.current?.getBoundingClientRect();
    if (!rect) return 0;

    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    return rect.left + scrollbarWidth / 2;
  }

  const handleMouseLeave = () => {
    if (!isTouchDevice) {
      setMegaNavigationTimeout(
        window.setTimeout(() => {
          toggleMegaNavigation(false)
        }, 300)
      )
    }
  }

  return (
    <div ref={btnRef}>
      <DropdownButton
        id={`meganav-${id}`}
        isActive={isActive}
        className={className}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </DropdownButton>
    </div>
  )
}

export default MegaNavigationButton
