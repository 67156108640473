import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import type {
  SanityProductFragment,
  SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { defaultOptionName, defaultOptionValue } from '@lib/product/product'
import { getVariantFromOptions } from '@lib/product/variant'

import InputDropdown from '@components/input-dropdown/with-form-register'

interface ProductOptionsSelectProps {
  product: SanityProductFragment
  activeVariant: SanityProductVariantFragment
  onChange: (variant: SanityProductVariantFragment) => void
  onPreview?: (variant?: SanityProductVariantFragment) => void
}

interface ChangeEvent {
  target: {
    value: string
  }
}

const ProductOptionsSelect = ({
  product,
  activeVariant,
  onChange,
  onPreview,
}: ProductOptionsSelectProps) => {
  const form = useForm()

  const isDefaultOptionOnly = useMemo(() => {
    if (!activeVariant?.options || activeVariant.options.length !== 1) {
      return false
    }

    const option = activeVariant.options[0]

    return (
      option.name === defaultOptionName && option.value === defaultOptionValue
    )
  }, [activeVariant])
  const formRegisters = useMemo(
    () => product.options.map((option) => form.register(option.name)),
    [form, product]
  )

  const handleChange = useCallback(
    async (event: ChangeEvent, optionName: string) => {
      if (!product?.variants || !activeVariant) {
        return
      }

      const newVariant = getVariantFromOptions(
        product?.variants,
        activeVariant.options,
        optionName,
        event.target.value
      )

      if (newVariant && newVariant.variantID !== activeVariant.variantID) {
        onChange(newVariant)
        onPreview?.(newVariant)
      }
    },
    [activeVariant, onChange, onPreview, product]
  )

  if (product.options.length === 0 || isDefaultOptionOnly) {
    return null
  }

  return (
    <div className="max-w-sm w-full space-y-5">
      {product.options.map((option, index) => (
        <InputDropdown
          key={option.name}
          defaultValue={option.values[0]}
          formRegister={{
            ...formRegisters[index],
            onChange: async (event) => handleChange(event, option.name),
          }}
          label={option.name}
          options={option.values.map((value) => ({
            title: value,
            value,
          }))}
        />
      ))}
    </div>
  )
}

export default ProductOptionsSelect
