import cx from 'classnames'

import MenuItem, { type ItemType } from './menu-item'

interface MenuProps {
  id?: string
  items: ItemType[]
  hasFocus?: boolean
  useMegaNav?: boolean
  onClick?: () => void
  className?: string
  isFooterMenu?: boolean
  isFooterLegalMenu?: boolean
  isHeaderDesktopMenu?: boolean
  isHeaderMobilePrimaryMenu?: boolean
  isHeaderMobileSecondaryMenu?: boolean
  isMegaNavMenu?: boolean
}

const Menu = ({
  id,
  items,
  hasFocus = true,
  useMegaNav = false,
  onClick,
  isFooterMenu,
  isFooterLegalMenu,
  isHeaderDesktopMenu,
  isHeaderMobilePrimaryMenu,
  isHeaderMobileSecondaryMenu,
  isMegaNavMenu,
  className,
}: MenuProps) => {
  return (
    <ul
      className={cx(
        {
          'flex flex-col gap-y-2': isFooterMenu,
          'flex flex-col xs:flex-row gap-5': isFooterLegalMenu,
          'flex flex-wrap gap-x-5 gap-y-2': isHeaderDesktopMenu,
          'flex flex-col gap-y-3.5': isMegaNavMenu
        },
        className
      )}
    >
      {items.map((item) => {
        return (
          <li key={item._key}>
            <MenuItem
              menuId={id}
              item={item}
              hasFocus={hasFocus}
              onClick={onClick}
              useMegaNav={useMegaNav}
              isFooterMenuItem={isFooterMenu}
              isFooterLegalMenuItem={isFooterLegalMenu}
              isHeaderDesktopMenuItem={isHeaderDesktopMenu}
              isHeaderMobilePrimaryMenuItem={isHeaderMobilePrimaryMenu}
              isHeaderMobileSecondaryMenuItem={isHeaderMobileSecondaryMenu}
              isMegaNavMenuItem={isMegaNavMenu}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default Menu
