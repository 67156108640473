import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useContext, useEffect, useRef, useMemo, useState } from 'react'
import { useResizeObserver } from 'usehooks-ts'

import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'
import { swipeAnimation } from '@lib/animate'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'

import FeaturedProducts from './featured-products'
import Menu from './menu'

interface MegaNavigationDropdownProps {
  dropdown: SanityMenuFeaturedLinkFragment
  hasFocus: boolean
  setHasFocus: (hasFocus: boolean) => void
  setActiveDropdownHeight: (activeDropdownHeight: number) => void
  leftOffset?: number
}

const MegaNavigationDropdown = ({
                                  dropdown,
                                  hasFocus,
                                  setHasFocus,
                                  setActiveDropdownHeight,
                                  leftOffset
                                }: MegaNavigationDropdownProps) => {
  const strings = useContext(StringsContext)
  const { megaNavigation, toggleMegaNavigation } = useContext(SiteContext)

  const dropdownRef = useRef<HTMLDivElement>(null)
  const dropdownRectangle = useResizeObserver({
    ref: dropdownRef
  })

  const [lastValidLeftOffset, setLastValidLeftOffset] = useState<number | undefined>(leftOffset)

  const isDropdownActive = useMemo(
    () => megaNavigation.isOpen && megaNavigation.activeId === dropdown._key,
    [dropdown._key, megaNavigation.activeId, megaNavigation.isOpen]
  )

  useEffect(() => {
    if (isDropdownActive && typeof dropdownRectangle.height !== 'undefined') {
      setActiveDropdownHeight(dropdownRectangle.height)
    }
  }, [dropdownRectangle.height, isDropdownActive, setActiveDropdownHeight])

  useEffect(() => {
    if (leftOffset !== undefined && leftOffset !== 0) {
      setLastValidLeftOffset(leftOffset) // Store last valid leftOffset before closing
    }
  }, [leftOffset])

  return (
    <div
      ref={dropdownRef}
      id={`meganav-${dropdown._key}`}
      className={cx(
        'absolute top-0 inset-x-0 z-10 -mt-px overflow-hidden transition-visibility duration-200',
        {
          'pointer-events-auto': isDropdownActive,
          'pointer-events-none': !isDropdownActive
        }
      )}
    >
      <AnimatePresence>
        {isDropdownActive && (
          <motion.div
            initial={{ opacity: 0, x: lastValidLeftOffset ?? 0 }}
            animate={{ opacity: 1, x: leftOffset ?? 0 }}
            exit={{ opacity: 0, x: lastValidLeftOffset ?? 0 }} // Prevents jumping on fade-out
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            onAnimationComplete={() => setHasFocus(isDropdownActive)}
            variants={swipeAnimation}
            className={cx('grid grid-cols-2 relative py-7 pb-10 will-change-transform', {
              'container': !leftOffset
            })}
          >
            <Menu
              items={dropdown.dropdownItems}
              hasFocus={hasFocus && isDropdownActive}
              onClick={() => toggleMegaNavigation(false)}
              isMegaNavMenu
            />

            {dropdown.featured && dropdown.featured.length > 0 && (
              <div className='grid grid-cols-2 gap-4 max-w-2xl relative w-full pl-8'>
                <div className='block absolute top-0 right-full w-2 text-xs uppercase tracking-widest font-semibold'>
                  <span
                    className='block overflow-hidden absolute top-1/2 left-0 whitespace-nowrap transform rotate-90 origin-top-left'>
                    {strings.featuredProducts}
                  </span>
                </div>
                <FeaturedProducts
                  products={dropdown.featured}
                  onClick={() => toggleMegaNavigation(false)}
                />
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default MegaNavigationDropdown
