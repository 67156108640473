import { useContext } from 'react'
import cx from 'classnames'
import { SanityProductVariantFragment } from '@data/sanity/queries/types/product'
import { StringsContext } from '@lib/strings-context'

interface ProductStockLabelProps {
  productVariant: SanityProductVariantFragment
  size?: 'xs' | 'sm'
}

const ProductStockLabel = ({
  productVariant,
  size = 'xs',
}: ProductStockLabelProps) => {
  const strings = useContext(StringsContext)

  const isInStock = productVariant.inStock && !productVariant.lowStock
  const isLowStock = productVariant.inStock && productVariant.lowStock
  const outOfStock = !productVariant.inStock

  return (
    <span
      className={cx('inline-flex items-center gap-1.5 shrink-0', {
        'text-xs': size === 'xs',
        'text-sm': size === 'sm',
      })}
    >
      <span
        className={cx('w-2 h-2 rounded-full', {
          'bg-success': isInStock,
          'bg-warning': isLowStock,
          'bg-error': outOfStock,
        })}
      />

      {isInStock && <>{strings.productInStock}</>}
      {isLowStock && <>{strings.productLowStock}</>}
      {outOfStock && <>{strings.productOutOfStock}</>}
    </span>
  )
}

export default ProductStockLabel
