import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useMemo } from 'react'

import {
  type SanityProductGalleryPhoto,
  type SanityProductListingPhoto,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { thumbnailAnimation } from '@lib/animate'
import { hasObject } from '@lib/helpers'
import { getProductGalleryPhoto } from '@lib/product/images'

import Photo from '@components/photo'

interface ProductThumbnailProps {
  galleryPhotos?: SanityProductGalleryPhoto[]
  listingPhotos?: SanityProductListingPhoto[]
  activeVariant: SanityProductVariantFragment
}

const ProductThumbnail = ({
  galleryPhotos,
  listingPhotos,
  activeVariant,
}: ProductThumbnailProps) => {
  const listingPhoto = useMemo(() => {
    const activeVariantThumbnail = listingPhotos?.find((listingPhoto) => {
      if (!listingPhoto.forOption) {
        return false
      }

      const option = {
        name: listingPhoto.forOption.split(':')[0],
        value: listingPhoto.forOption.split(':')[1],
      }

      return !!option.value && hasObject(activeVariant.options, option)
    })

    if (activeVariantThumbnail) {
      return activeVariantThumbnail
    }

    const defaultThumbnail = listingPhotos?.find(
      (listingPhoto) => !listingPhoto.forOption
    )

    return defaultThumbnail
  }, [activeVariant.options, listingPhotos])

  if (!listingPhoto) {
    return null
  }

  const thumbnailImage = getProductGalleryPhoto(
    galleryPhotos ?? [],
    listingPhoto.forOption,
    listingPhoto.galleryImage
  )
  const thumbnailHoverImage = getProductGalleryPhoto(
    galleryPhotos ?? [],
    listingPhoto.forOption,
    listingPhoto.galleryImageHover
  )

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={`${listingPhoto.galleryImage ?? ''}${
          listingPhoto.galleryImageHover ?? ''
        }`}
        initial="hide"
        animate="show"
        exit="hide"
        variants={thumbnailAnimation}
        className="relative overflow-hidden"
      >
        {!!thumbnailImage && (
          <Photo
            image={thumbnailImage}
            sizes="(min-width: 1200px) 33vw, (min-width: 768px) 50vw, 100vw"
            fadeIn
          />
        )}

        {!!thumbnailHoverImage && (
          <Photo
            image={thumbnailHoverImage}
            sizes="(min-width: 1200px) 33vw, (min-width: 768px) 50vw, 100vw"
            className={cx(
              'absolute inset-0 z-[2] opacity-0 invisible transition-all',
              'group-hover:opacity-100 group-hover:visible'
            )}
            fadeIn
          />
        )}

        {/* TODO: Placeholder */}
      </motion.div>
    </AnimatePresence>
  )
}

export default ProductThumbnail
