import cx from 'classnames'
import { type ReactNode, useContext, useMemo } from 'react'

import { CartContext } from '@lib/cart/context'
import { getCartItemCount } from '@lib/cart/helpers'

import Icon from '@components/icon'

interface CartToggleProps {
  onClick: () => void
  className?: string
  children?: ReactNode
  showCartCount?: boolean
  showCartIcon?: boolean
}

const CartToggle = ({
  onClick,
  className,
  showCartCount,
  children,
  showCartIcon,
}: CartToggleProps) => {
  const { cart } = useContext(CartContext)

  const cartItemCount = useMemo(() => getCartItemCount(cart), [cart])

  return (
    <button
      onClick={onClick}
      className={cx(
        'appearance-none no-underline cursor-pointer font-inherit flex items-center relative bg-transparent p-1 font-semibold',
        className
      )}
    >
      {showCartIcon && (
        <Icon
          id="cart"
          name="Cart"
          className={cx('text-current text-xl', {
            'mr-2': children && !showCartCount,
            'mr-4': children && showCartCount,
          })}
        />
      )}

      {children}

      {showCartCount && (
        <span
          className={cx(
            'min-w-[1rem] min-h-[1rem] rounded-full bg-cartCountBadgeBg text-pageBG text-xs flex items-center justify-center',
            {
              'opacity-50': cartItemCount === 0,
              'ml-2': children && !showCartIcon,
              'absolute top-0 left-5': showCartIcon,
            }
          )}
        >
          {cartItemCount}
        </span>
      )}
    </button>
  )
}

export default CartToggle
